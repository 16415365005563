@font-face {
  font-family: 'Metropolis-regular';
  src: url('/src/assets/fonts/Metropolis-Regular.otf');
}

@font-face {
  font-family: 'Metropolis-bold';
  src: url('/src/assets/fonts/Metropolis-Bold.otf');
}

@font-face {
  font-family: 'Roboto-regular';
  src: url('/src/assets/fonts/Roboto-Regular.ttf');
}

body {
  margin: 0;
  font-family: 'Metropolis', sans-serif;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #438edc;
}

::-webkit-scrollbar-thumb:hover {
  background: #1e7add;
}
