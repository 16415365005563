.login {
  position: relative;
  width: 100vw;
}

.login-body {
  margin-left: 100px;
  width: 40%;
  max-width: 500px;
}

.login-content {
  margin-top: 50px;
}

.login-content h1 {
  font-size: 300%;
  font-weight: 700;
  font-family: 'Metropolis-bold', sans-serif;
}

.form p {
  margin-top: 20px;
  font-size: 1.25em;
  font-weight: 400;
  font-family: 'Metropolis-regular', sans-serif;
  color: red;
  text-align: center;
}

.login-content a {
  text-decoration: none;
  color: rgb(66, 158, 243);
}

.form {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.text-input {
  padding: 10px;
  width: 100%;
  border: 2px solid black;
  border-radius: 15px;
  font-family: 'Roboto-regular', sans-serif;
  font-weight: 200;
  font-size: 100%;
  margin-top: 20px;
  outline: none;
}

.checkbox {
  margin-top: 20px;
  margin-right: 5px;
  border: 1px solid black;
  outline: none;
  border-radius: 30px;
}

.showPassword {
  font-size: 120%;
}

.form button {
  text-decoration: none;
  border: none;
  width: 100%;
  padding: 15px 15px;
  background: #4285f4;
  border-radius: 10px;
  font-family: 'Metropolis-bold', sans-serif;
  color: white;
  font-size: 25px;
  margin-top: 30px;
  margin-bottom: 40px;
  cursor: pointer;
  outline: none;
}

.form button:disabled {
  background: #6285f4;
  cursor: default;
}

.login-background {
  position: absolute;
  top: 10%;
  right: 5%;
  width: 40%;
}

@media only screen and (min-device-width: 768px)  and (max-width: 1024px) {
  .login {
    margin: 0;
    width: calc(100vw - 10px);
  }

  .login-body {
    width: 100%;
    margin: auto;
    text-align: center;
  }

  .showPassword {
    font-size: 150%;
  }

  .login-content h1 {
    font-size: 400%;
  }

  .form button {
    font-size: 40px;
  }

  .text-input {
    font-size: 150%;
  }

  .form p {
    font-size: 1rem;
    text-align: center;
  }

  .login-background {
    top: 100%;
    width: 70%;
    right: 15%;
  }
}

@media only screen and (max-device-width: 768px) {
  .login {
    margin: 0;
  }

  .login-body {
    width: 80%;
    margin: auto;
    text-align: center;
  }

  .login-content {
    width: 100%;
    text-align: center;
  }

  .login-background {
    top: 100%;
    width: 70%;
    right: 15%;
  }
}
