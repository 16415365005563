.send-body {
  display: flex;
  flex-direction: column;
  width: 60%;
  margin: 20px auto;
}

.details {
  padding: 10px;
  width: 100%;
  border: 1px solid black;
  border-radius: 15px;
  font-family: 'Roboto-regular', sans-serif;
  font-weight: 200;
  font-size: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  outline: none;
  -webkit-appearance: none;
}

.details:focus {
  border-color: cornflowerblue;
  outline: none;
}

.details.has-error {
  border: 2px solid tomato;
}

.send-btn-group {
  margin: 15px 0 0 0;
  text-align: center;
}

.csv {
  margin-top: 2px;
  width: fit-content;
  background: #4285f4;
  color: white;
  font-size: 1rem;
  padding: 10px 35px;
  margin-right: 10px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-family: 'Metropolis', sans-serif;
}

.send {
  margin-top: 2px;
  width: fit-content;
  background: #4285f4;
  color: white;
  font-size: 1rem;
  padding: 10px 35px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-family: 'Metropolis', sans-serif;
}

.test {
  margin-right: 10px;
}

.send:disabled {
  background: #6f96d4;
  color: white;
  cursor: default;
}

/* modal */

.modalClose {
  display: none;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modalOpen {
  display: block;
  position: fixed;
  z-index: 6;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modalOpen-content {
  background-color: #fefefe;
  margin: auto;
  padding: 30px;
  border: 1px solid #888;
  width: 70%;
  border-radius: 20px;
}

.modalOpen-content.confirm {
  width: 40%;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.recipients-input {
  width: 100%;
  height: 45px;
  padding: 0 1rem;
  margin-top: 1rem;
  box-sizing: border-box;
  font: inherit;
  border-radius: 0.2rem;
  border: 2px solid #d4d5d6;
  color: #565656;
  -webkit-appearance: none;
}

.recipients-input:focus {
  border-color: cornflowerblue;
  outline: none;
}

.recipients-input.has-error {
  border-color: tomato;
}

.tag-item {
  background-color: #d4d5d6;
  font-size: 14px;
  border-radius: 30px;
  height: 30px;
  padding: 0 4px 0 1rem;
  display: inline-flex;
  align-items: center;
  margin: 0 0.3rem 0.3rem 0;
}

.tag-item > .button {
  background-color: white;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  font: inherit;
  margin-left: 10px;
  font-weight: bold;
  padding: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.send-confirm {
  display: block;
  height: 53px;
  margin: auto;
  margin-top: 30px;
  background: #4285f4;
  color: white;
  padding: 12px 35px;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  font-size: 1.2rem;
}

.send-test {
  display: block;
  width: 26%;
  height: 53px;
  margin: auto;
  margin-top: 30px;
  background: #4285f4;
  color: white;
  padding: 12px 35px;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  font-size: 1.2rem;
}

.send-test:disabled,
.send-confirm:disabled {
  background: #6f96d4;
  color: white;
  cursor: default;
}

.send-test.send-button {
  height: fit-content;
  width: 30%;
}

.message-sent svg {
  width: 100px;
  display: block;
  margin: 40px auto 0;
}

.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
}

.path.circle {
  -webkit-animation: dash 0.9s ease-in-out;
  animation: dash 0.9s ease-in-out;
}

.path.line {
  stroke-dashoffset: 1000;
  -webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
  animation: dash 0.9s 0.35s ease-in-out forwards;
}

.path.check {
  stroke-dashoffset: -100;
  -webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
  animation: dash-check 0.9s 0.35s ease-in-out forwards;
}

.error {
  text-align: center;
  margin: 20px 0 60px;
  font-size: 1.25em;
  color: #d06079;
}

@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }

  100% {
    stroke-dashoffset: 900;
  }
}

@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }

  100% {
    stroke-dashoffset: 900;
  }
}

/* editor */

.gjs .gjs-editor {
  height: 100vh;
  max-width: 100vw;
  overflow: hidden;
}

.gjs-mdl-container {
  max-height: 100vh;
  overflow: hidden;
}

.gjs-mdl-content {
  height: calc(50vh + 20px);
}

.gjs-am-file-uploader form {
  height: 50vh;
  padding-bottom: 0;
}

.gjs-am-assets {
  height: 50vh;
}

.gjs-am-assets-cont {
  height: 50vh;
}

.gjs-mdl-dialog {
  height: calc(55vh + 20px);
}

.gjs-pn-btn {
  margin-bottom: 0;
}

@media only screen and (min-device-width: 768px)  and (max-width: 1024px) {
  .send-body {
    display: block;
    width: 80%;
  }

  .details {
    width: 100%;
  }

  .send-btn-group {
    margin: 15px 0;
    text-align: center;
  }

  .send-test.send-button {
    width: 45%;
  }

  .gjs-editor {
    max-width: 100vw;
    overflow: hidden;
  }

  .gjs-frame {
    left: -10%;
    width: 90%;
  }

  div[data-gjs-type="mj-section"] {
    left: -15%;
    width: 85%;
  }

  .gjs-pn-views,
  .gjs-pn-views-container {
    width: 20%;
  }

  .gjs-pn-options {
    width: 27%;
    right: 23%;
  }

  .gjs-pn-btn {
    min-height: 5px;
    min-width: 5px;
    font-size: 18px;
  }

  .gjs-block {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .send-body {
    display: block;
    width: 80%;
  }

  .details {
    width: 100%;
    margin-bottom: 10px;
  }

  .send-btn-group {
    margin: 10px 0;
    text-align: center;
  }

  .send {
    width: calc(35% - 5px);
    font-size: 1rem;
    padding: 9px 5%;
    border: none;
    cursor: pointer;
    margin-top: 5px;
  }

  .send-test.send-button {
    width: 80%;
    height: fit-content;
  }

  .csv {
    width: 70%;
    background: #4285f4;
    color: white;
    font-size: 1rem;
    padding: 9px 35px;
    border: none;
    cursor: pointer;
    margin-right: 0;
  }

  .modalOpen-content.confirm {
    width: 90%;
    max-width: 500px;
  }

  .gjs-editor {
    height: 100vh;
    max-width: 100vw;
    overflow: auto;
  }

  .gjs.cv.canvas {
    width: 65%;
  }

  .gjs-pn-views {
    width: 35%;
  }

  .gjs-pn-views-container {
    width: 35%;
  }

  .gjs-pn-options {
    right: 25%;
  }

  .gjs-pn-btn {
    min-height: 5px;
    min-width: 5px;
    font-size: 10px;
  }

  .gjs-block {
    width: 100%;
  }
}
