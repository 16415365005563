* {
  margin: 0;
}

.home {
  position: relative;
  height: calc(100% - 85px);
  overflow: hidden;
}

.home-body {
  padding-top: 30px;
  width: 100vw;
  margin-left: 50px;
  text-align: start;
}

.home-body h1 {
  font-size: 300%;
  font-weight: 300;
  font-family: 'Metropolis-bold', sans-serif;
  width: 67%;
  margin: 50px 30px;
}

.home-body p {
  margin: 20px 30px;
  font-size: 150%;
  font-weight: 200;
  font-family: 'Metropolis', sans-serif;
  width: 50%;
  text-align: start;
}

.home-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  padding-top: 30px;
  margin-left: 40px;
}

.home-buttons a,
.home-buttons button {
  text-decoration: none;
  text-align: center;
  padding: 15px 60px;
  font-size: 1.5em;
  font-family: 'Metropolis-bold', sans-serif;
  margin-top: 30px;
  border-radius: 5px;
  width: 100%;
  border: 2px solid #4285f4;
  background: #4285f4;
  color: white;
  cursor: pointer;
}

.homeImg {
  display: block;
  position: absolute;
  right: 5%;
  bottom: 10%;
  width: 45%;
}

@media only screen and (min-device-width: 767px)  and (max-width: 1024px) {
  .home {
    min-height: 930px;
    overflow: hidden;
  }

  .home-body {
    padding: 0;
    margin: 0;
    text-align: center;
  }

  .home-body h1 {
    width: 100%;
    padding-top: 30px;
    margin: 50px 0;
    font-size: 300%;
    text-align: center;
  }

  .home-body p {
    width: 100%;
    margin: 20px auto;
    text-align: center;
  }

  .home-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    padding: 0 50px;
    max-width: 750px;
    margin: auto;
    margin-top: 50px;
  }

  .homeImg {
    position: absolute;
    margin-bottom: 40px;
    bottom: -5%;
    right: 20%;
    width: 60%;
  }
}

@media only screen and (max-device-width: 767px) {
  .home {
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .home-body {
    padding: 0 20px;
    margin: 0;
  }

  .home-body p {
    width: 100%;
    margin: 0;
    padding: 10px 10%;
    text-align: center;
  }

  .home-body h1 {
    padding: 0;
    width: 100%;
    padding-top: 0;
    margin: 30px 0;
    font-size: 250%;
    text-align: center;
  }

  .home-buttons {
    padding-top: 10px;
    text-align: center;
    margin: auto;
    margin-bottom: 30px;
    width: 75%;
  }

  .home-buttons a,
  .home-buttons button {
    width: 100%;
    max-width: 400px;
    padding: 15px 30px;
  }

  .homeImg {
    display: none;
  }
}
