* {
  box-sizing: border-box;
  font-family: 'Metropolis-regular', sans-serif;
}

.upload-body {
  align-items: center;
  height: calc(100% - 85px);
  background-color: black;
  padding-top: 50px;
}

.upload-content {
  color: white;
  padding-left: 50px;
  font-family: 'Monospace-bold', sans-serif;
}

.upload-content h1 {
  font-size: 2rem;
  font-weight: 600;
}

.upload-content h4 {
  font-size: 1.5rem;
  margin-top: 20px;
  font-weight: 300;
}

.upload-box {
  background-color: #eee;
  margin: 10px 30px;
  border: 2px rgb(9, 144, 255) dashed;
  border-radius: 10px;
}

.upload-box:hover {
  border: 2px rgb(9, 144, 255) solid;
}

.upload-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 33%;
  margin: 10px auto;
  background-color: white;
  margin-top: 50px;
  border-radius: 10px;
  padding: 40px 0;
}

.upload-container h3 {
  font-size: 1.5rem;
  font-family: 'Monospace-bold', sans-serif;
}

.next-button {
  text-decoration: none;
  padding: 10px 50px;
  font-size: 1.5em;
  font-family: 'Metropolis-bold', sans-serif;
  margin-top: 30px;
  border-radius: 10px;
  width: 70%;
  border: 2px solid #4285f4;
  color: white;
  outline: none;
  cursor: pointer;
  background-color: #4285f4;
}

button:disabled {
  color: #80a8eee4;
  cursor: default;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile + label {
  font-size: 1.25em;
  font-weight: 700;
  color: white;
  display: inline-block;
  outline: none;
  cursor: pointer;
  max-width: 80%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0.625rem 1.25rem;
}

.js .inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile + label svg {
  width: 1em;
  height: 1em;
  vertical-align: middle;
  fill: currentColor;
  margin-top: -0.25em;
  margin-right: 0.25em;
}

span {
  margin-bottom: 10px;
}

.inputfile-4 + label {
  color: rgb(12, 152, 233);
}

.inputfile-4 + label figure {
  width: 100px;
  height: 100px;
  display: block;
  padding: 20px 20px 10px 20px;
  margin: 0 auto;
}

.inputfile-4 + label svg {
  width: 100%;
  height: 100%;
  fill: #f1e5e6;
}

.no-js .inputfile + label {
  display: none;
}

@media only screen and (min-device-width: 768px)  and (max-width: 1024px) {
  .upload-content {
    padding-top: 70px;
    padding-left: 50px;
    margin-bottom: 40px;
  }

  .upload-body {
    padding-top: 0;
  }

  .upload-container {
    width: 50%;
    margin-top: 0;
  }
}

@media only screen and (max-width: 767px) {
  .upload-content {
    padding-top: 50px;
    padding-left: 0;
    text-align: center;
    margin-bottom: 40px;
  }

  .upload-body {
    padding-top: 0;
  }

  .upload-container {
    width: 80%;
    margin-top: 0;
  }

  .upload-container h3 {
    font-size: 1.4rem;
  }

  .upload-content h1 {
    font-size: 1.5rem;
  }

  .upload-content h4 {
    font-size: 1.2rem;
  }
}
